globalThis.__VUE_OPTIONS_API__ = false;
globalThis.__VUE_PROD_DEVTOOLS__ = false;
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;


import { createApp } from "vue";
import App from "./App.vue";

import router from './router';
import VueTheMask from 'vue-the-mask';
import * as styles from "bootstrap/dist/css/bootstrap.css"
import * as style from "../css/style.css"
import * as bootstrap from 'bootstrap';

const app = createApp(App);
app.use(router);
app.use(VueTheMask);
app.mount("#app");

